import type { VFC } from 'react';
import React, { Fragment } from 'react';

import { Input } from '../../Form/Input/Input';
import { EditorsSelect } from '../../Form/EditorsSelect/EditorsSelect';

type EditorialFieldsProps = {
  inputsSize: 'big' | 'small' | 'compact';
};

const EditorialFields: VFC<EditorialFieldsProps> = ({ inputsSize }) => {
  return (
    <Fragment>
      <EditorsSelect label="Editor" name="editor" size={inputsSize} />
      <Input type="textarea" label="Content" name="content" size="small" />
    </Fragment>
  );
};

export { EditorialFields };
