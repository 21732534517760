import type { VFC } from 'react';
import React from 'react';

import { Select } from '../Select/Select';
import { useEditors } from '../../../hooks/useEditors';

type EditorsSelectProps = {
  label: string;
  name: string;
  allowEmpty?: boolean;
  disabled?: boolean;
  size: 'big' | 'small' | 'compact';
  className?: string;
};

const EditorsSelect: VFC<EditorsSelectProps> = ({ ...props }) => {
  const { data: editors = [], isLoading } = useEditors();

  const options = editors.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return <Select {...props} options={options} isLoading={isLoading} />;
};

export { EditorsSelect };
